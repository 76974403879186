import {
  badgeIcon,
  badgeIconActive,
  briefcaseIcon,
  briefcaseIconActive,
  envelope,
  envelopeOpen,
  logoutIcon,
  newspaperIcon,
  newspaperIconActive,
  dumbell,
  dumbellActive,
  userIcon,
  userIconActive,
  cashIcon,
  cashIconActive,
  referralIcon,
  referralIconActive,
} from 'assets';
import { featureFlagNames } from 'common/constants/global';

const MENU = [
  {
    icon: briefcaseIcon,
    iconActive: briefcaseIconActive,
    title: 'Jobs',
    link: '/admin/job-list',
  },
  {
    icon: userIcon,
    iconActive: userIconActive,
    title: 'Users',
    link: '/admin/user-list',
  },
  {
    icon: badgeIcon,
    iconActive: badgeIconActive,
    title: 'Badges',
    link: '/admin/badges',
    featureFlagGuard: featureFlagNames.disableAdminBadges,
  },
  {
    icon: newspaperIcon,
    iconActive: newspaperIconActive,
    title: 'Logs',
    link: '/admin/log-list',
    featureFlagGuard: featureFlagNames.disableAdminInessentials,
  },
  {
    icon: dumbell,
    iconActive: dumbellActive,
    title: 'Training Modules',
    link: '/admin/training-job',
    featureFlagGuard: featureFlagNames.disableAdminInessentials,
  },
  {
    icon: briefcaseIcon,
    iconActive: briefcaseIconActive,
    title: 'Coin Adjustment',
    link: '/admin/coin-tagger-list',
    featureFlagGuard: featureFlagNames.disableAdminInessentials,
  },
  {
    icon: referralIcon,
    iconActive: referralIconActive,
    title: 'Referrals',
    link: '/admin/referrals',
    featureFlagGuard: featureFlagNames.disableAdminInessentials,
  },
  {
    icon: cashIcon,
    iconActive: cashIconActive,
    title: 'Cash Prize',
    link: '/admin/cash-prize-requests',
    featureFlagGuard: featureFlagNames.disableCashRedemption,
  },
  {
    icon: briefcaseIcon,
    iconActive: briefcaseIconActive,
    title: 'Leaderboard',
    link: '/admin/leaderboard',
    featureFlagGuard: featureFlagNames.disableAdminInessentials,
  },
  {
    icon: envelope,
    iconActive: envelopeOpen,
    title: 'Invite Codes',
    link: '/admin/invite-codes/list',
    featureFlagGuard: featureFlagNames.disableAdminInessentials,
  },
  {
    icon: logoutIcon,
    iconActive: logoutIcon,
    title: 'Log Out',
    link: '/admin/logout',
  },
];
export { MENU };
